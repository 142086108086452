import React from "react";


// const Model = ({ modelUrl }) => {
//   const gltf = useLoader(GLTFLoader, modelUrl);
//   return <primitive object={gltf.scene} scale={0.5} />;
// };

const Visualize3D = () => {
  return(
    <></>
  )
};

export default Visualize3D;
