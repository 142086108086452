// contextpage/context.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

// Create CartContext
const CartContext = createContext();

// Custom hook to use CartContext
export const useCart = () => useContext(CartContext);

// CartProvider component
export const CartProvider = ({ children }) => {
  // User-related states


  // Cart and wishlist states
  const [count, setCount] = useState(0);
  const [wishlistCount, setWishlistCount] = useState(0);
  const [cartItems, setCartItems] = useState(() => {
    const storedCartItems = localStorage.getItem("cartItems");
    return storedCartItems ? JSON.parse(storedCartItems) : [];
  });

  const [wishlist, setWishlist] = useState(() => {
    const storedWishlist = localStorage.getItem("wishlist");
    return storedWishlist ? JSON.parse(storedWishlist) : [];
  });

  // Synchronize wishlist with localStorage and update count
  useEffect(() => {
    setWishlistCount(wishlist.length);
    localStorage.setItem("wishlist", JSON.stringify(wishlist));
  }, [wishlist]);

  // Synchronize cartItems with localStorage and update count
  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
    setCount(cartItems.reduce((total, item) => total + item.quantity, 0));
  }, [cartItems]);

  // Function to add an item to the cart
  const addToCart = (item) => {
    setCartItems((prevCartItems) => {
      const existingItem = prevCartItems.find(
        (cartItem) =>
          cartItem.id === item.id && cartItem.uniqueId === item.uniqueId
      );
      if (existingItem) {
        // Update quantity if item already exists
        return prevCartItems.map((cartItem) =>
          cartItem.id === item.id && cartItem.uniqueId === item.uniqueId
            ? { ...cartItem, quantity: cartItem.quantity + item.quantity }
            : cartItem
        );
      } else {
        // Add new item to the cart
        return [
          ...prevCartItems,
          { ...item, uniqueId: uuidv4(), quantity: item.quantity },
        ];
      }
    });
  };

  // Function to remove an item from the cart
  const removeFromCart = (itemId) => {
    setCartItems((prevCartItems) =>
      prevCartItems.filter((item) => item.uniqueId !== itemId)
    );
  };

  // Function to update the quantity of a cart item
  const updateQuantity = (itemId, newQuantity) => {
    setCartItems((prevCartItems) =>
      prevCartItems.map((item) =>
        item.uniqueId === itemId ? { ...item, quantity: newQuantity } : item
      )
    );
  };

  // Function to add an item to the wishlist
  const addToWishlist = (product) => {
    setWishlist((prevWishlist) => {
      if (prevWishlist.some((item) => item._id === product._id)) {
        return prevWishlist; // Product is already in wishlist
      }
      const updatedWishlist = [...prevWishlist, product];
      localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));
      return updatedWishlist;
    });
  };

  // Function to remove an item from the wishlist
  const removeFromWishlist = (productId) => {
    setWishlist((prevWishlist) => {
      const updatedWishlist = prevWishlist.filter(
        (item) => item._id !== productId
      );
      localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));
      return updatedWishlist;
    });
  };






  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        updateQuantity,
        count,
       
        wishlist,
        addToWishlist,
        removeFromWishlist,
        wishlistCount,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
