import React from "react";
import "./newshipped.css";
const Newchekout = () => {
  return (
    <>
      <header>
        <h3>Checkout</h3>
      </header>

    

    
    </>
  );
};

export default Newchekout;
